import { Box, Button, Text, VStack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { AccountPageType } from './account-drawer'
import { TitleSection } from './account-title-section'
import { SectionDivider } from '../section-divider'
import { redirectToAuth0Login, redirectToAuth0Register } from 'frontastic/contexts/authV2UserContext/helpers'
import { RewardsClubBanner } from './pages/account-dashboard/components/rewards-club-banner'
import { useRouter } from 'next/router'
import { SideBarJoinDXLRewards } from '../sidebar/sidebar-join-dxl-rewards'

export interface LoginFormProps {
  type?: AccountPageType
}

export const LoginForm = ({ type = AccountPageType.PAGE }: LoginFormProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()

  const content = {
    ariaLabel: {
      signIn: formatMessage({ id: 'account.login.title' }),
    },
    title: formatMessage({ id: 'account.login.title' }),
    description: formatMessage({ id: 'account.login.description' }),
    notAMemberYet: formatMessage({ id: 'account.login.notAMemberYet' }),
    or: formatMessage({ id: 'text.or' }),
    button: {
      login: formatMessage({ id: 'action.login' }),
      register: formatMessage({ id: 'action.needToRegister' }),
    },
  }

  const getUrlWithParams = (baseUrl, params) => {
    let url = baseUrl
    const queryParams = new URLSearchParams(params)
    if (queryParams.toString().length > 0) {
      url += `?${queryParams.toString()}`
    }
    return url
  }

  const currentRoute = getUrlWithParams(router.asPath, { lastAction: 'login' })

  return (
    <Box>
      <TitleSection type={type} title={content.title} description={content.description} />
      <Box py={6}>
        <VStack mt={6} display="flex" justifyContent="stretch" gap={{ base: 4, md: 6 }}>
          <Button type="submit" colorScheme="blue" width={'full'} onClick={() => redirectToAuth0Login(currentRoute)}>
            <Text textStyle={'heading-mobile-200'} fontSize="16px">
              {content.button.login}
            </Text>
          </Button>
          <Box width={'full'}>
            <SectionDivider text={content.or} textProps={{ textStyle: 'body-75' }} />
          </Box>

          <Text width={'full'} textStyle={'heading-desktop-100'} fontWeight="600">
            {content.notAMemberYet}
          </Text>

          <Button type="submit" colorScheme="blue" width={'full'} variant={'outline'} onClick={redirectToAuth0Register}>
            <Text textStyle={'heading-mobile-200'} fontSize="16px">
              {content.button.register}
            </Text>
          </Button>
        </VStack>
        <SideBarJoinDXLRewards />
      </Box>
    </Box>
  )
}
