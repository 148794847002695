import { Text, VStack } from '@chakra-ui/react'

export interface TitleSectionProps {
  type: 'page' | 'drawer'
  title: string
  description: string
}

export const TitleSection = ({ type, title, description }: TitleSectionProps) => {
  return (
    <>
      {type === 'page' ? (
        <VStack>
          <Text as={'h3'} textStyle={{ base: 'heading-mobile-300', md: 'heading-desktop-400' }} alignSelf={'center'}>
            {title}
          </Text>
          <Text textStyle={'callouts-blockquote-75-Default'} alignSelf={'center'} textAlign={'center'}>
            {description}
          </Text>
        </VStack>
      ) : (
        <VStack alignItems={'flex-start'} spacing={0}>
          <Text as={'h3'} textStyle={{ base: 'heading-mobile-200', md: 'heading-desktop-200' }} mb={2}>
            {title}
          </Text>
          <Text textStyle={{ base: 'heading-body-75', md: 'heading-body-100' }} fontSize={{ base: '14px', md: '16px' }}>
            {description}
          </Text>
        </VStack>
      )}
    </>
  )
}
